import {  useEffect, useState } from "react";
import "./OverallDifficultyWiseProgress.scss";
import { isEmpty } from "../../../custom_utilities/custom_useful_functions";
import {
  CircularProgressbar,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

///////Canvas Chart//////////////////

const OverallDifficultyWiseProgress = (props) => {
  let { dataPoints } = props;
  const [toughness, setToughness] = useState(dataPoints[0].Toughness);
  const [array, setArray] = useState([]);

  useEffect(() => {
    filterToughnessWise();
  }, [toughness]);

  const filterToughnessWise = () => {
    dataPoints?.filter((element) => {
      if (element.Toughness === toughness) {
        setArray([element]);
         
      }
    });
    return;
  };

  const handleChange = (event) => {
    const { nodeName, value } = event.target;
    if (nodeName === "INPUT") {
      setToughness(value);
    }
  };

  return (
    <div className="sec_1_inner_wrapper">
      <div className="heading-text">Difficulty wise</div> <hr></hr>
      <div className="toughness_collection_wrapper">
        {!isEmpty(dataPoints)
          ? dataPoints.length
            ? dataPoints.map((element, index) => {
                return (
                  <div key={index} className="single_select_toughness">
                    <input
                      type="radio"
                      id={element.Toughness}
                      // name="individual_test_subject"
                      value={element.Toughness}
                      checked={element.Toughness === toughness}
                      onChange={handleChange}
                    />
                    <label htmlFor={element.Toughness}>
                      {element.Toughness}
                    </label>
                  </div>
                );
              })
            : null
          : null}
      </div>
      <div className="progress_box_wrapper">
        <div className="progress_box">
          <div className="progress-circle">
            <div className="progress-circle-top">
              <div className="progress-circle-1">
                <CircularProgressbar
                  styles={buildStyles({
                    textSize: "15px",
                    pathColor: "#FF933A",
                    borderRadius: "50%",
                    width: "150",
                    backgroundColor: "#ECC5A5",
                    border: "4px solid black",
                  })}
                  className="circular"
                  sqSize="200"
                  value={array[0]?.Correct}
                  text={`${array[0]?.Correct} Q's
                  `}
                  strokeWidth={5}
                  background="#ECC5A5"
                />

                <span>Wrong</span>
              </div>

              <div className="progress-circle-2">
                <CircularProgressbar
                  styles={buildStyles({
                    textSize: "15px",
                    backgroundColor: "#ACEECB",
                    pathColor: "#31D680",
                  })}
                  className="circular"
                  value={array[0]?.WRONG}
                  text={`${array[0]?.WRONG} Q's`}
                  strokeWidth={5}
                  // background="red"
                  background="#ACEECB"
                />
                <span>Unattempted</span>
              </div>
            </div>

            <div className="progress-circle-bottom">
              <div className="progress-circle-3">
                <CircularProgressbar
                  styles={buildStyles({
                    textSize: "15px",
                    backgroundColor: "#BBCCF2",

                    pathColor: "#668DE1",
                  })}
                  className="circular"
                  value={array[0]?.Unattempted}
                  text={`${array[0]?.Unattempted} Q's`}
                  strokeWidth={5}
                  background="#BBCCF2"
                />
                <span className="progress-circle-text">Correct</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverallDifficultyWiseProgress;
