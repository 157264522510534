import { Fragment, useEffect, useState } from "react";
import "./dasboardSlider.scss";
import axios from "axios";
import { url_78 } from "../../../../../../custom_utilities/api_services";
import { isEmpty } from "../../../../../../custom_utilities/custom_useful_functions";
import images from "../../../../../../utilities/images/images";

//slick-carousel
import Slider from "react-slick";

const DashboardSlider = () => {
  const [state, setState] = useState([]);
  useEffect(() => {
    getSliderImg();
  }, []);
  const getSliderImg = async () => {
    try {
      const response = await axios.get(url_78, {});
     
      if (response.data.status === 200) {
        setState([...response.data.data]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Fragment>
      <div className="dashboard_slider_wrapper">
        <div className="dashboard_slider_inner_wrapper">
          <Slider {...settings}>
            {!isEmpty(state) ? (
              state.map((element, index) => {
                return (
                  <div
                    className="single_image_wrapper"
                    key={element.banner_image}
                  >
                    <div className="single_image_inner_wrapper">
                      <img src={element.img} alt="img" />
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="single_image_wrapper">
                <div className="single_image_inner_wrapper">
                  <img src={images.user_dashboard_8} alt="img" />
                </div>
              </div>
            )}
          </Slider>
        </div>
      </div>
    </Fragment>
  );
};

export default DashboardSlider;
