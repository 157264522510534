import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  testDate: "",
  paperId: "",
  plannerTestId: "",
  papers: {},
  expectedRank: "",
  allIndiaRank: "",
};

console.log("initialState in TestDataSlice", initialState);

const parentTestSlice = createSlice({
  name: "parentTest",
  initialState,
  reducers: {
    setPaperId: (state, action) => {
      state.paperId = action.payload;
    },
    setPlannerTestId: (state, action) => {
      state.plannerTestId = action.payload;
    },
    setTestDate: (state, action) => {
      state.testDate = action.payload;
    },
    setPapers: (state, action) => {
      state.papers = action.payload;
    },
    setExpectedRank: (state, action) => {
      state.expectedRank = action.payload;
    },
    setAllIndiaRank: (state, action) => {
      state.allIndiaRank = action.payload;
    },
    setPlannerId: (state, action) => {
      state.plannerTestId = action.payload;
    },
  },
});

export const {
  setPaperId,
  setPlannerTestId,
  setTestDate,
  setPapers,
  setExpectedRank,
  setAllIndiaRank,
  setPlannerId,
} = parentTestSlice.actions;

export const parentTestReducer = parentTestSlice.reducer;
