import { React, useState } from "react";
import { useLocation } from "react-router-dom";
import "./OtsBehaviour.scss";

import {
  url_132,
  url_134,
} from "../../../../../../../../custom_utilities/api_services";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import images from "../../../../../../../../utilities/images/images";
import { isEmpty } from ".././../../../../../../../custom_utilities/custom_useful_functions";
import HeaderComponent from "../../../../../../../logged_user_components/structure_components/desktop_screens/header_component/header_component";
import OtsBehaviourChart from "./OtsBehaviourChart";
import ScatterChart from "../../../../../../../../components/canvas_charts/canvas_doughnut_chart/ScatterChart";
import axios from "axios";
import { useEffect } from "react";
import LoaderPopUp from "../../../../../../../../components/loader/loader";
import { useHistory } from "react-router-dom";
//mobileDevice
import MobileLeftSideBarComponent from "../../../../../../structure_components/mobile_screens/mobile_left_sideBar_component/mobile_left_sideBar_component";
import MobileHeaderComponent from "../../../../../../structure_components/mobile_screens/mobile_header_component/mobile_header_component";
import MobileRightSideBarComponent from "../../../../../../structure_components/mobile_screens/mobile_right_sideBar_component/mobile__right_sideBar_component";
//Redux
import { connect } from "react-redux";
import uiActions from "../../../../.././../../../redux/ui/action";

const OtsBehaviour = (props) => {
  let {
    startLoading,
    finishLoading,
    loader,
    userData,
    userPreference,
    testPaperResultData,
    token,
    tokenType,
  } = props;
  let history = useHistory();
  const backBtn = () => {
    history.push("/ots_test_result");
  };
  const location = useLocation();
  const [currentSubject, setCurrentSubject] = useState(null);
  const [state, setState] = useState({
    behaviourTime: {},
    scatterChartPoint: null,
    subjectList: {},
  });
  useEffect(() => {
    getDifficultyResult();
  }, [currentSubject]);
  useEffect(() => {
    getSubjectList();
  }, []);

  //getSubjectList
  const getSubjectList = async () => {
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };

    let data = {
      paper_id: testPaperResultData?.PaperData?.paper_id,
    };
    startLoading();
    try {
      const response = await axios.post(url_132, data, { headers });
      if (response.data.status === 200) {
        setState((preState) => ({
          ...preState,
          subjectList: response.data.data,
        }));
        response.data.data.All_Subject[0] &&
          setCurrentSubject(response.data.data.All_Subject[0]?.subject);
        finishLoading();
      }
    } catch (error) {
      console.log(error);
      finishLoading();
    }
  };
  //getBehaviour
  const handleChangeSubject = (event) => {
    let { value } = event.target;
    setCurrentSubject(value);
  };
  //topicWiseData
  const getDifficultyResult = async () => {
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };

    let data = {
      paper_id: testPaperResultData?.PaperData?.paper_id,
      test_id: testPaperResultData?.PaperData?.testid,
      subject: currentSubject,
    };
    startLoading();
    try {
      const response = await axios.post(url_134, data, { headers });
      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          behaviourTime: response.data.data,
        }));
        convertDataPoints(response.data.data[`Time Per Question`]);
      } else {
        setState((prevState) => ({
          ...prevState,
          behaviourTime: "",
        }));
      }
      finishLoading();
    } catch (error) {
      console.log(error);
      finishLoading();
    }
  };
  //let progressCount = (100 / testQuestionsDetails.length) * currentQuestion + 1;
  const convertDataPoints = (array) => {
    let correctPoint = [];
    let wrongPoint = [];
    for (let i = 0; i < array.length; i++) {
      if (array[i].Type == "RIGHT") {
        correctPoint.push({ x: i + 1, y: parseInt(array[i].Time) });
      } else {
        wrongPoint.push({ x: i + 1, y: parseInt(array[i].Time) });
      }
    }
    setState((preState) => ({
      ...preState,
      scatterChartPoint: { correctPoint, wrongPoint },
    }));
  };
  const isMob = () => {
    if (window.innerWidth <= 480) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <>
      <div className="otsBehaviourWrapper">
        {isMob() ? (
          <>
            <MobileLeftSideBarComponent />
            <MobileHeaderComponent
              userPreference={userPreference}
              userData={userData}
            />
            <MobileRightSideBarComponent history={history} />
          </>
        ) : (
          <HeaderComponent />
        )}
        <div className="behaviourInrWrapper">
          <div className="container">
            <div className="sbjtWiseMarksInr">
              <div className="backBtn" onClick={backBtn}>
                <img src={images.user_activity_1} />
              </div>

              <div className="difficultyLevel">
                <h3>Behaviour</h3>
                <div className="difficultyLevelTab">
                  <div className="difficultyLevelTabBox">
                    {!isEmpty(state.subjectList)
                      ? state.subjectList.All_Subject.length
                        ? state.subjectList.All_Subject.map(
                            (element, index) => {
                              return (
                                <div
                                  key={index}
                                  className="single_select_subject"
                                >
                                  <input
                                    type="radio"
                                    id={element.subject}
                                    name="subject"
                                    value={element.subject}
                                    checked={element.subject === currentSubject}
                                    onChange={(event) => {
                                      handleChangeSubject(event);
                                    }}
                                  />
                                  <label htmlFor={element.subject}>
                                    {element.subject}
                                  </label>
                                </div>
                              );
                            }
                          )
                        : null
                      : null}
                  </div>
                  {loader && <LoaderPopUp />}
                  <div className="difficultyLevelcontent">
                    <div className="scatterChart">
                      <ScatterChart dataPoints={state.scatterChartPoint} />
                    </div>
                    <div className="scorePercentageBox margin0">
                      <div className="scoreChart mediumResult">
                        <CircularProgressbar
                          value={
                            state.behaviourTime?.[`Total Time`]
                              ? state.behaviourTime?.[`Total Time`]
                              : 0
                          }
                          strokeWidth={10}
                        />
                      </div>
                      <div className="scoreDetail">
                        <p>Student Attempted Tough Questions</p>
                      </div>
                    </div>
                    <div className="scorePercentageBox">
                      <div className="scoreChart hardResult">
                        <OtsBehaviourChart dataPoints={state.behaviourTime} />
                      </div>
                      <div className="scoreDetail">
                        <p>Time Spent on C, W, U</p>
                        <div className="percentageDiv correct">
                          <span></span>Correct
                        </div>
                        <div className="percentageDiv wrong">
                          <span></span>
                          Wrong
                        </div>
                        <div className="percentageDiv unattempt">
                          <span></span>
                          unattempted
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    userData: state.auth.user,
    userPreference: state.userPreference,
    testPaperResultData: state?.otsTestsData?.otsPaperResultData,
    token: state.auth.authToken,
    tokenType: state.auth.tokenType,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OtsBehaviour);
