import React from 'react';

const ControlPanel = ({ scale,zoomIn,zoomOut}) => {
 
  return (
    <div className="" style={{  display: "flex",justifyContent:"end",alignItems:"center",marginBottom:"10px"}}>
        <i
          className={`fas fa-search-minus mx-3`}
          onClick={zoomOut}
          style={{cursor:"pointer"}}
        />
        {/* <span>{(scale * 100).toFixed()}%</span> */}
        <i
          className={`fas fa-search-plus mx-3`}
          onClick={zoomIn}
          style={{cursor:"pointer"}}

        />
      </div>
  );
};

export default ControlPanel;
