// import { Fragment, useEffect, useRef, useState } from "react";
// import axios from "axios";
// // import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
// // import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

// import "./notes_feature_content_component.scss";
// import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
// import images from "../../../../../utilities/images/images";
// import ViewSDKClient from "../../../../../custom_utilities/AdobeSDK/ViewSDKClient";
// // import demo from "./demo.pdf"

// // import { EmbedPDF } from "@simplepdf/react-embed-pdf";
// import ControlPanel from "./ControlPanel";




// const NotesFeatureContentComponent = (props) => {
//   let { history, notesData, tokenType, token } = props;
//   pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

//   const [numPages, setNumPages] = useState(null);
//   const [pdfData, setPdfData] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [scale, setScale] = useState(1);

//   useEffect(() => {
//     getPdfData();
//   }, []);


//   useEffect(() => {
//     // Function to determine scale based on window width
//     const updateScale = () => {
//       if (window.innerWidth >= 1024) {
//         setScale(1); // Desktop
//       } else if (window.innerWidth >= 768 && window.innerWidth < 1024) {
//         setScale(0.5); // iPad/Tablet
//       } else {
//         setScale(0.5); // Default to 1 for other sizes, such as mobile
//       }
//     };

//     // Add event listener
//     window.addEventListener('resize', updateScale);

//     // Call handler right away so state gets updated with initial window size
//     updateScale();

//     // Remove event listener on cleanup
//     return () => window.removeEventListener('resize', updateScale);
//   }, []);
  


//   const handleRedirect = () => {
//     history.goBack();
//   };

//   console.log('pdfData', pdfData);

//   const getPdfData = async () => {
//     try {
//       const response = await axios.get(
//         `https://learning.motion.ac.in/motioneducation/api/notes?file=${
//           notesData.notes.match(/\/([^/]+)$/)[1]
//         }&id=${notesData.notes_id}`,
//         {
//           headers: {
//             Authorization: `${tokenType} ${token}`,
//           },
//           responseType: "arraybuffer", // Specify response type as arraybuffer
//         }
//       );

//       if (response.status === 200) {
//         setPdfData(response.data);
//         setLoading(false);
//       }
//     } catch (error) {
//       console.error("Error fetching PDF:", error);
//       setLoading(false);
//     }
//   };

//   const handleZoomIn = () => {
//     setScale(scale * 1.2); // Increase scale by 20%
//   };

//   const handleZoomOut = () => {
//     setScale(scale / 1.2); // Decrease scale by 20%
//   };


//   return (
//     <Fragment>
//       <div className="notesFeatureContentComponent_wrapper">
//         <div className="container">
//           <div className="notesFeatureContentComponent_inner_wrapper ">
//               <div className="sec_1_inner_wrapper">
//                 <div className="btn_wrapper">
//                   <button onClick={() => handleRedirect()}>
//                     <img src={images.user_activity_1} alt="Back" />
//                   </button>
//                 </div>
//                 <div className="text_content_wrapper">
//                   <p className="text_content">{notesData.notes_title}</p>
//                 </div>
//               </div>
//             <div className="notes_wrapper">
//               {loading ? (
//                 <p></p>
//               ) : (
//                   <>
                    
//                   <ControlPanel zoomIn={handleZoomIn} zoomOut={handleZoomOut} setScale={setScale} scale={scale} />

//                 <div id="pdf-div" className="notes_inner_wrapper">
//                     <Document
//                      file={{ data: pdfData }}
//                     onLoadSuccess={({ numPages }) => setNumPages(numPages)}
//                       onContextMenu={(e) => e.preventDefault()}
//                       renderMode="canvas"
//                       scale={1.5}
                      
//                   >
//                     {/* {Array.from(new Array(numPages), (el, index) => (
//                       <Page key={`page_${index + 1}`} pageNumber={index + 1} />
//                     ))} */}
                        
//                     {Array.from(new Array(numPages), (el, index) => (
//                       <Page
//                         key={`page_${index + 1}`}
//                         pageNumber={index + 1}
//                         scale={scale} // Pass scale as a prop
//                       />
//                     ))}

                        

//                   </Document>
                    
//                   {/* <iframe className="iframer" id='iframerr' src={`${notesData.notes}#toolbar=0`}  frameborder="0" */}
//                     {/* /> */}

//                     {/* <iframe className="iframer" id='iframerr' src={`${notesData.notes}`}  frameborder="0"
//                     /> */}

//  {/* <EmbedPDF
//    mode="inline"
//   style={{ width: 900, height: 800 }}
//   documentURL={notesData.notes}
//  /> */}

                    
//                     <>
//                       {/* <EmbedPDF
//   mode="inline"
//   style={{ width: 900, height: 800 }}
//   documentArrayBuffer={pdfData}
// /> */}


//       </>
                      
//                     </div>
//                   </>
                    
//               )}
//             </div>
//           </div>
//         </div>
//       </div>
//     </Fragment>
//   );
// };

// export default NotesFeatureContentComponent;







// // // const NotesFeatureContentComponent = () => {
  

// // //   return (
// // //     <>

// // //  <EmbedPDF
// // //   mode="inline"
// // //   style={{ width: 900, height: 800 }}
// // //   documentURL="https://cdn.simplepdf.eu/simple-pdf/assets/sample.pdf"
// // // />

// // //  <EmbedPDF
// // //   mode="inline"
// // //   style={{ width: 900, height: 800 }}
// // //       />
// // //       </>
// // //   )

// // // }


// // // export default  NotesFeatureContentComponent;



// // import { Fragment, useEffect, useState } from "react";
// // import axios from "axios";
// // import { Document, Page, pdfjs } from "react-pdf";
// // import images from "../../../../../utilities/images/images";

// // const NotesFeatureContentComponent = (props) => {
// //   let { history, notesData, tokenType, token } = props;

// //   const [pdfData, setPdfData] = useState(null);
// //   const [numPages, setNumPages] = useState(null);
// //   const [loading, setLoading] = useState(true);
// //   const [scale, setScale] = useState(1); // Initial scale

// //   useEffect(() => {
// //     getPdfData();
// //   }, []);

// //   const handleRedirect = () => {
// //     history.goBack();
// //   };

// //   const getPdfData = async () => {
// //     try {
// //       const response = await axios.get(
// //         `https://learning.motion.ac.in/motioneducation/api/notes?file=${
// //           notesData.notes.match(/\/([^/]+)$/)[1]
// //         }&id=${notesData.notes_id}`,
// //         {
// //           headers: {
// //             Authorization: `${tokenType} ${token}`,
// //           },
// //           responseType: "arraybuffer", // Specify response type as arraybuffer
// //         }
// //       );

// //       if (response.status === 200) {
// //         setPdfData(response.data);
// //         setLoading(false);
// //       }
// //     } catch (error) {
// //       console.error("Error fetching PDF:", error);
// //       setLoading(false);
// //     }
// //   };

// //   const zoomIn = () => {
// //     setScale(scale + 0.2); // Increase scale
// //   };

// //   const zoomOut = () => {
// //     setScale(scale - 0.2); // Decrease scale
// //   };

// //   return (
// //     <Fragment>
// //       <div className="notesFeatureContentComponent_wrapper">
// //         <div className="container">
// //           <div className="notesFeatureContentComponent_inner_wrapper ">
// //             <div className="sec_1_wrapper">
// //               <div className="sec_1_inner_wrapper">
// //                 <div className="btn_wrapper">
// //                   <button onClick={() => handleRedirect()}>
// //                     <img src={images.user_activity_1} alt="Back" />
// //                   </button>
// //                 </div>
// //                 <div className="text_content_wrapper">
// //                   <p className="text_content">{notesData.notes_title}</p>
// //                 </div>
// //               </div>
// //             </div>
// //             <div className="notes_wrapper">
// //               {loading ? (
// //                 <p>Loading...</p>
// //               ) : (
// //                 <div className="notes_inner_wrapper">
// //                   <div className="zoom_controls">
// //                     <button onClick={zoomIn}>Zoom In</button>
// //                     <button onClick={zoomOut}>Zoom Out</button>
// //                   </div>
// //                   <Document
// //                     file={{ data: pdfData }}
// //                     onLoadSuccess={({ numPages }) => setNumPages(numPages)}
// //                   >
// //                     {Array.from(new Array(numPages), (el, index) => (
// //                       <Page key={`page_${index + 1}`} pageNumber={index + 1} scale={scale} />
// //                     ))}
// //                   </Document>
// //                 </div>
// //               )}
// //             </div>
// //           </div>
// //         </div>
// //       </div>
// //     </Fragment>
// //   );
// // };

// // export default NotesFeatureContentComponent;


// // import React, { useState } from "react";
// // import { Document, Page, pdfjs } from "react-pdf";

// // //PDFjs worker from an external cdn
// // const url =
// //   "https://learning.motion.ac.in/motioneducation//uploads/notes/pdf1713621213.pdf";

// // export default function Test() {
// //   pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
// //   const [numPages, setNumPages] = useState(null);
// //   const [pageNumber, setPageNumber] = useState(1);

// //   function onDocumentLoadSuccess({ numPages }) {
// //     setNumPages(numPages);
// //     setPageNumber(1);
// //   }
// //   return (
// //     <>
// //       <div className="main">
// //         <Document file={url} onLoadSuccess={onDocumentLoadSuccess} renderMode = "canvas">
// //           <Page pageNumber={pageNumber} scale={1.5}/>
// //         </Document>
// //       </div>
// //     </>
// //   );
// // }


import { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import images from "../../../../../utilities/images/images";
import ControlPanel from "./ControlPanel";
import "./notes_feature_content_component.scss";

const NotesFeatureContentComponent = ({
  history,
  notesData,
  tokenType,
  token,
}) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const [numPages, setNumPages] = useState(null);
  const [pdfData, setPdfData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [scale, setScale] = useState(1.5);
  const [pageWidth, setPageWidth] = useState(null); 

  useEffect(() => {
    const getPdfData = async () => {
      try {
        const response = await axios.get(
          `https://learning.motion.ac.in/motioneducation/api/notes?file=${
            notesData.notes.match(/\/([^/]+)$/)[1]
          }&id=${notesData.notes_id}`,
          {
            headers: {
              Authorization: `${tokenType} ${token}`,
            },
            responseType: "arraybuffer",
          }
        );

        if (response.status === 200) {
          setPdfData(response.data);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching PDF:", error);
        setLoading(false);
      }
    };

    getPdfData();
  }, [notesData.notes, notesData.notes_id, token, tokenType]);

  useEffect(() => {
    const calculatePageWidth = () => {
      const maxWidth = Math.min(window.innerWidth - 40, 800); 
      setPageWidth(maxWidth);
    };

    calculatePageWidth();
    window.addEventListener("resize", calculatePageWidth);

    return () => window.removeEventListener("resize", calculatePageWidth);
  }, []);

  useEffect(() => {
    const updateScale = () => {
      if (window.innerWidth >= 1024) {
        setScale(1.5);
      } else if (window.innerWidth >= 768 && window.innerWidth < 1024) {
        setScale(1.2);
      } else {
        setScale(1);
      }
    };

    window.addEventListener("resize", updateScale);
    updateScale();

    return () => window.removeEventListener("resize", updateScale);
  }, []);

  const handleRedirect = () => {
    history.goBack();
  };

  const handleZoomIn = () => {
    setScale((prevScale) => prevScale * 1.2);
  };

  const handleZoomOut = () => {
    setScale((prevScale) => prevScale / 1.2);
  };

  return (
    <Fragment>
      <div className="notesFeatureContentComponent_wrapper">
        <div className="pdf-container">
          <div className="notesFeatureContentComponent_inner_wrapper">
            {/* <div className="sec_1_inner_wrapper">
              <div className="btn_wrapper">
                <button onClick={handleRedirect}>
                  <img src={images.user_activity_1} alt="Back" />
                </button>
              </div>
              <div className="text_content_wrapper">
                <p className="text_content--">{notesData.notes_title}</p>
              </div>
            </div> */}




            <div className="pdf-header">
              <div className="btn_wrapper">
                <button onClick={handleRedirect}>
                  <img src={images.user_activity_1} alt="Back" />
                </button>
              </div>
              <div className="headingContentWrapper">
                <div className="titleText">{notesData.notes_title}</div>
              </div>
            </div>
            <div className="notes_wrapper">
              {loading ? (
                <p></p>
              ) : (
                <>
                  <ControlPanel
                    zoomIn={handleZoomIn}
                    zoomOut={handleZoomOut}
                    setScale={setScale}
                    scale={scale}
                  />
                  <div id="pdf-div" className="notes_inner_wrapper">
                    <div className="pdf-container">
                      <Document
                        file={{ data: pdfData }}
                        onLoadSuccess={({ numPages }) => setNumPages(numPages)}
                        onContextMenu={(e) => e.preventDefault()}
                        renderMode="canvas"
                      >
                        {Array.from(new Array(numPages), (el, index) => (
                          <Page
                            key={`page_${index + 1}`}
                            pageNumber={index + 1}
                            width={pageWidth} 
                            scale={scale}
                          />
                        ))}
                      </Document>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default NotesFeatureContentComponent;