import React, { useEffect, useState,useRef } from "react";
import "./StreamingVideoDetails.scss";
import images from "../../../../../../../utilities/images/images";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { url_216 ,url_217} from "../../../../../../../custom_utilities/api_services";
// import { BASE_URL } from "../../../../../../../custom_utilities/baseURL";
import { connect } from "react-redux";

const StreamingVideoDetails = ({ streamingDetails, isLive, live_class_id,token,tokenType }) => {
  const history = useHistory();
  const { sub_name, topic_name, faculty, watch_count ,meeting_topic} = streamingDetails;

  const intervalRef = useRef(null);


  useEffect(() => {

    getViewsCount();

     if (isLive) {
      intervalRef.current = setInterval(() => {
        getViewsCount();
      }, 20000);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };

 
  }, []);


   // Handle backbutton
   const onBackButtonEvent = (e) => {
     e.preventDefault();
     //  alert("Are you sure, You want to Leave the Class");
     
    

     if (isLive) {
     leaveClassApiCall();
    
       
     }
     
  };

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);

  
  // useEffect(() => {
  //   window.history.pushState(null, null, window.location.pathname);
  //   window.addEventListener("popstate", onBackButtonEvent);
  // }, [show]);
  //


  const [viewsCount, setViewsCount] = useState(0);



  const leaveClassApiCall = async () => {


    const headers = {
      Authorization: `${tokenType} ${token}`
    };

  
    


    const response = await axios.post(`https://learning.motion.ac.in/motioneducation/api/v1/live/viewers/update?live_class_id=${live_class_id}&status=left`, { headers });

    if (response.data.status === 200) {

      // alert("Are you sure, You want to Leave the Class");
      
      history.push("/user_dashboard");
    } else {
      
      // alert("Are you sure, You want to Leave the Class");
      
      history.push("/user_dashboard")


    }
      





  }




  const leaveClass = () => {
    alert("Are you sure you want to leave meeting?");

    leaveClassApiCall();
   

  };

  const handleBackButton = () => {


    if (isLive) {
      alert("Are you sure you want to leave meeting?");
      
      history.push("/user_dashboard");

    leaveClassApiCall();



    } else {
      
      history.push( "/topic_component");
    }

    
  




  }

  const getViewsCount = async () => {
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };
    try {
      const response = await axios.get(`${url_216}?live_class_id=${live_class_id}`, headers);
      console.log(response.data.data.viewers_count);
      setViewsCount(response.data.data.viewers_count);
    } catch (error) {
      console.log(error)
    }
  }

  const classEditor = (isLive) => {
   return  isLive ? "is-live" : "is-recorded-video"
  }

  return (
    <div className={`video-player-container ${classEditor(isLive)}`} >
      <div className="video-details-watch-count">
        <div className="video-details">
          
          <img src={images.backBtn1} alt="back-btn" style={{ width: "2.25rem", height: "2.25rem", marginRight: "0.5rem", cursor: "pointer" }}
            
            onClick={() => {
              handleBackButton();
           
          }
          } />
          
          <div className="subject-name">{sub_name}</div>

          <span className="morethan-symbol">
            <img src={images.greaterThan} alt="greater-than" />
          </span>

          <div className="topic-name">{topic_name}</div>
        </div>

        {isLive &&
          <div className="watch-count">
            <div className="img-and-count">
              <img src={images.eyeView} alt="eye-view" />
              {viewsCount}
            </div>
          </div>
        }
      </div>

      <iframe
        src={streamingDetails?.playbackurl}
        height="80%"
        width="100%"
        title="Iframe Example"
        allow="fullscreen"
        className="video-player"
      ></iframe>

      <div className="more-details">
        {isLive && <div className="live">
          <div className="live-circle"></div>
          <div className="live-text">Live</div>
        </div>}

        <div className="topic-and-leave-class">
          <div className="topic">{meeting_topic}</div>
          {isLive && <div className="leave-class-btn" onClick={leaveClass}>
            Leave Class
          </div>}
        </div>

        {/* <div className="tutor-details">
          <div className="tutor-profile-pic">
            <img src={images.nvSirProPic} alt="nv-sir-pic" />
            <div className="tutor-name">{faculty}</div>
          </div>

          <div className="tutor-rating">
            <div>
              <img alt="rating" src={images.ratingIcon} />
            </div>

            <div className="rating">5</div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.authToken,
    tokenType: state.auth.tokenType,

    
  };
};

export default connect(mapStateToProps, 0)(StreamingVideoDetails);
